import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { useMediaQuery } from '@mui/material';
import getCorrections from '../../../../../../utils/functions/getCorrections';
import data from '../../data';
import getScore from '../../../../../../utils/functions/getScore';
import getWordLimit from '../../../../../../utils/functions/getWordLimit';
import notify from '../../../../../../utils/notify';
import {
  checkText,
  getText,
  acceptSuggestion,
  dismissSuggestion,
  saveTitle,
  saveText
} from '../../../../../../stories/actions/reviewer';
import {
  EditorState,
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  ContentState
} from 'draft-js';
import decorators from '../../components/Editor/components/Text/decorators';
import { throttle, debounce } from 'underscore';
import { getSettings } from '../../components/Editor/components/Text/functions/getSettings';
import { convertToHTML } from 'draft-convert';
import { createEditorStateFromRaw } from 'draftail';
import SuggestionStrategy from '../../components/Editor/components/Text/decorators/Suggestion/function/SuggestionStrategy';
import SuggestionComponent from '../../components/Editor/components/Text/decorators/Suggestion/components/SuggestionComponent';
import createCorrectionEntities from '../../components/Editor/components/Text/decorators/function/createCorrectionEntities';
import removeCorrectionEntities from '../../components/Editor/components/Text/decorators/function/removeCorrectionEntities';
import { EditorContext } from '../../../../../../utils/contexts/EditorContext';
import { EditorContextInterface } from '../../../../../../utils/contexts/EditorContext/interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useReviewer = (dispatch: any) => {
  const { translate }: TranslateInterface = useLanguage();

  const ctx: EditorContextInterface = useContext(EditorContext);

  const location = useLocation();

  const { activeItem, onSetActiveItem, activeCategory, onSetActiveCategory } =
    ctx;

  const isMobile: boolean = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const [loaded, setLoaded] = useState(false);

  const [loading, setLoading] = useState(false);

  const [modalScoreOpen, setModalScoreOpen] = useState(false);
  const [modalSummaryOpen, setModalSummaryOpen] = useState(false);
  const [modalPremiumOpen, setModalPremiumOpen] = useState(false);

  const [innerHeight, setInnerHeight] = useState<any>();

  const categories = data.categories;

  const [id, setId] = useState(localStorage.getItem('textId'));

  const [title, setTitle] = React.useState('');

  const onSetTitle = (title: string) => {
    setTitle(title);
  };

  const onSaveTitle = (props: any) => {
    const cb = {
      success: (data: any) => {
        setId(data.id);
        setTitle(data.title);

        localStorage.setItem('textId', data.id);
      },
      error: () => {}
    };

    dispatch(saveTitle(props, cb));
  };

  const compositeDecorator = new CompositeDecorator([
    decorators(activeItem, activeCategory),
    { strategy: SuggestionStrategy, component: SuggestionComponent }
  ]);

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(compositeDecorator)
  );

  const [text, setText] = useState('');

  const summary = '';

  const [corrections, setCorrections]: any = useState([]);

  const [premium, setPremium] = useState([]);

  const { score }: any = useMemo(
    () => getScore(corrections || []),
    [corrections]
  );

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  const onProcess = (props: any) => {
    const { limit, counter } = getWordLimit(props.text);

    if (counter > limit) {
      return notify.error('O texto excede o limite de palavras!');
    }

    if (!loading) {
      setLoading(true);

      const cb = {
        success: (data: any) => {
          const formatted = getCorrections(
            {
              corrections: data.corrections
            },
            props.activeCategory
          );

          setCorrections(formatted.corrections);

          setPremium(formatted.premium);

          setId(data.id);
          setTitle(data.title);
          setText(data.text);

          if (data?.editorState) {
            const contentState = createEditorStateFromRaw({
              blocks: data?.editorState?.blocks,
              entityMap: data?.editorState?.entityMap || {}
            });

            // const state = createCorrectionEntities(
            //   removeCorrectionEntities(contentState),
            //   [...formatted.corrections, ...formatted.premium]
            // );

            // setEditorState(contentState);
          }

          localStorage.setItem('textId', data.id);

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(checkText(props, cb));
    }
  };

  const onCheck = React.useCallback(
    debounce(
      (props: any) => {
        const { limit, counter } = getWordLimit(props.text);

        if (counter > limit) {
          return notify.error('O texto excede o limite de palavras!');
        }

        setLoading(true);

        throttledCheck(props);
      },
      isMobile ? 3000 : 2000
    ),
    []
  );

  const throttledCheck = React.useCallback(
    throttle(
      props => {
        if (!loading) {
          setLoading(true);

          const cb = {
            success: (data: any) => {
              const formatted = getCorrections(
                {
                  corrections: data.corrections
                },
                props.activeCategory
              );

              setCorrections(formatted.corrections);

              setPremium(formatted.premium);

              setId(data.id);
              setTitle(data.title);
              setText(data.text);

              if (data?.editorState) {
                const contentState = createEditorStateFromRaw({
                  blocks: data?.editorState?.blocks,
                  entityMap: data?.editorState?.entityMap || {}
                });

                // const state = createCorrectionEntities(
                //   removeCorrectionEntities(contentState),
                //   [...formatted.corrections, ...formatted.premium]
                // );

                // setEditorState(contentState);
              }

              localStorage.setItem('textId', data.id);

              setLoading(false);
            },
            error: () => {
              setLoading(false);
            }
          };

          dispatch(checkText(props, cb));
        }
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  const onSave = React.useCallback(
    debounce(
      (props: any) => {
        throttledSave(props);
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  const throttledSave = React.useCallback(
    throttle(
      (props: any) => {
        setLoading(true);

        const cb = {
          success: (data: any) => {
            const formatted = getCorrections(
              {
                corrections: data.corrections
              },
              props.activeCategory
            );

            setCorrections(formatted.corrections);

            setPremium(formatted.premium);

            setId(data.id);
            setTitle(data.title);
            setText(data.text);

            if (data?.editorState) {
              const contentState = createEditorStateFromRaw({
                blocks: data?.editorState?.blocks,
                entityMap: data?.editorState?.entityMap || {}
              });

              setEditorState(contentState);
            }

            localStorage.setItem('textId', data.id);

            setLoading(false);
          },
          error: () => {
            setLoading(false);
          }
        };

        dispatch(saveText(props, cb));
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  const onAccept = (correction: any) => {
    const newCorrections = corrections.filter(
      (item: any) => item._id !== correction._id
    );

    setCorrections(newCorrections);

    const cb = {
      success: () => {},
      error: () => {}
    };

    dispatch(acceptSuggestion(correction, cb));
  };

  const onDismiss = (correction: any) => {
    const newCorrections = corrections.filter(
      (item: any) => item._id !== correction._id
    );

    setCorrections(newCorrections);

    const cb = {
      success: () => {},
      error: () => {}
    };

    dispatch(dismissSuggestion(correction, cb));
  };

  const onCopyToClipboard = (e: any) => {
    const settings = getSettings();

    if (e) e.preventDefault();

    try {
      const range = document.createRange();
      const textToCopy = document.createElement('div');
      const selection: any = window.getSelection();
      textToCopy.innerHTML = convertToHTML(settings.htmlExporterConfig)(
        editorState.getCurrentContent()
      );

      textToCopy.id = 'copy-to-clipboard';
      document.body.append(textToCopy);
      range.selectNodeContents(textToCopy);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      textToCopy.remove();

      notify.success('Texto copiado! =)');
    } catch (e) {
      const textToCopy = document.querySelector('#copy-to-clipboard');
      if (textToCopy) {
        textToCopy.remove();
      }
    }
  };

  useEffect(() => {
    if (!loaded && !loading) {
      const textId = localStorage.getItem('textId');

      if (!textId) {
        setLoading(false);

        setLoaded(true);

        return;
      }

      setLoading(true);

      const cb = {
        success: (data: any) => {
          const formatted = getCorrections(
            {
              corrections: data.corrections
            },
            'all'
          );

          setCorrections(formatted.corrections);

          setPremium(formatted.premium);

          setId(data.id);
          setTitle(data.title);
          setText(data.text);

          if (data?.editorState) {
            const contentState = createEditorStateFromRaw({
              blocks: data?.editorState?.blocks,
              entityMap: data?.editorState?.entityMap || {}
            });

            setTimeout(() => {
              const state = createCorrectionEntities(
                removeCorrectionEntities(contentState),
                [...formatted.corrections, ...formatted.premium]
              );

              setEditorState(state);
            }, 500);
          }

          localStorage.setItem('textId', data.id);

          setLoading(false);

          setLoaded(true);
        },
        error: () => {
          setLoading(false);

          setLoaded(true);
        }
      };

      dispatch(getText(textId, cb));
    }
  }, [loaded, loading]);

  useEffect(() => {
    if (loaded && !loading) {
      const newCorrections =
        activeCategory === 'premium'
          ? premium
          : corrections.filter(item => {
              if (activeCategory === 'all') {
                return item.category !== 'premium';
              }

              return item.category === activeCategory;
            });

      const state = createCorrectionEntities(
        removeCorrectionEntities(editorState),
        newCorrections
      );

      setEditorState(state);
    }
  }, [activeCategory, loaded, loading, corrections]);

  const navigate = useNavigate();

  useEffect(() => {
    const textId = localStorage.getItem('textId');

    const beta = true;

    if (loaded && !textId && beta) {
      const searchParams = new URLSearchParams(location.search);
      const textParameter = searchParams.get('text');

      const decodedText = textParameter
        ? decodeURIComponent(textParameter)
        : '';

      console.log('decodedText:', { decodedText });

      if (decodedText !== '') {
        const newContentState = ContentState.createFromText(decodedText);

        const newEditorState = EditorState.createWithContent(newContentState);

        setEditorState(newEditorState);

        setText(decodedText);

        onCheck({
          title: '',
          text: decodedText,
          editorState,
          activeCategory: 'all',
          activeItem: null
        });

        navigate('/reviewer');
      }
    }

    if (textId) {
      navigate('/reviewer');
    }
  }, [location.search, loaded]);

  return {
    id,
    text,
    setText,
    editorState,
    setEditorState,
    title,
    onSetTitle,
    onSaveTitle,
    summary,
    score,
    corrections,
    premium,
    loading,
    loaded,
    innerHeight,
    modal: {
      score: {
        open: modalScoreOpen,
        toggle: () => {
          setModalScoreOpen(!modalScoreOpen);
        }
      },
      summary: {
        open: modalSummaryOpen,
        toggle: () => {
          setModalSummaryOpen(!modalSummaryOpen);
        }
      },
      premium: {
        open: modalPremiumOpen,
        toggle: () => {
          setModalPremiumOpen(!modalPremiumOpen);
        }
      }
    },
    isMobile,
    categories,
    activeCategory,
    onSetActiveCategory,
    activeItem,
    onSetActiveItem,
    onCheck,
    onSave,
    onProcess,
    onAccept,
    onDismiss,
    onCopyToClipboard
  };
};

export default useReviewer;
