import React, { useContext } from 'react';
import Root from './components/Root';
import Header from './components/Header';
import Body from './components/Body';
import { Grid } from '@mui/material';
import Chat from './components/Chat';
import Introduction from './components/Introduction';
import Loading from './components/Loading';
import Form from './components/Form';
import Prompts from './components/Prompts';
import Prompt from './components/Prompt';
import { useAppDispatch } from '../../../../utils/hooks/useAppDispatch';
import useForm from './hooks/useForm';
import ModalPremium from '../../components/Modal/Premium';

type Props = {};

const HashtagGeneratorPageLayout = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    modal,
    chosen,
    output,
    prompts,
    steps,
    step,
    form,
    loading,
    handleChange,
    handleSubmit,
    handlePrompt,
    handleReset
  } = useForm(dispatch);

  return (
    <Root>
      <Body>
        <ModalPremium
          open={modal.open}
          toggle={modal.toggle}
          type={modal.type}
        />

        <Grid
          container
          spacing={{
            xl: 2,
            lg: 2,
            md: 1,
            sm: 0,
            xs: 0
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Chat>
              {step === 1 && <Introduction />}

              {step !== 1 && (
                <Loading form={form} loading={loading} output={output} />
              )}

              <Form
                form={form}
                loading={loading}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />

              <Prompts>
                <Grid container spacing={2}>
                  {prompts.map((prompt: string, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={6}
                      key={index}
                    >
                      <Prompt handlePrompt={handlePrompt} prompt={prompt} />
                    </Grid>
                  ))}
                </Grid>
              </Prompts>
            </Chat>
          </Grid>
        </Grid>
      </Body>
    </Root>
  );
};

export default HashtagGeneratorPageLayout;
