import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppBody({ children }: RootInterface) {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: {
          xl: '164px',
          lg: '164px',
          md: '164px',
          sm: '160px',
          xs: '160px'
        },
        paddingLeft: {
          xl: '30%',
          lg: '30%',
          md: '30%',
          sm: '5%',
          xs: '5%'
        },
        paddingRight: {
          xl: '30%',
          lg: '30%',
          md: '30%',
          sm: '5%',
          xs: '5%'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '78px'
      }}
    >
      {children}
    </Box>
  );
}

export default AppBody;
