const validate: any = async (tool: string): Promise<any> => {
  const limit = 2;

  const json = localStorage.getItem(tool);

  const params = json
    ? JSON.parse(json)
    : {
        quantity: 0,
        date: new Date().toISOString()
      };

  const currentDate = new Date();
  const lastUsageDate = new Date(params.date);

  const diff = currentDate.getTime() - lastUsageDate.getTime();

  if (diff >= 1000 * 60 * 60 * 24) {
    params.quantity = 1;
    params.date = currentDate.toISOString();
  } else {
    if (params.quantity >= limit) {
      return 'LIMIT_REACHED';
    }

    params.quantity += 1;
  }

  localStorage.setItem(tool, JSON.stringify(params));

  return 'SUCCESS';
};

export default validate;
