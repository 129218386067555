import instance from '../../utils/axios/instance';

const GenerativeRequest = {
  generative: async (message: string, language: string) => {
    const { data } = await instance().post(`/generative`, {
      message,
      language
    });

    return data;
  },
  chosen: async (id: string, language: string) => {
    const { data } = await instance().put(
      `/generative/chosen/${language}/${id}`
    );

    return data;
  },
  statistics: async () => {
    const { data } = await instance().get(`/generative`);

    return data;
  },
  rewriter: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/re-writer`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  hashtagGenerator: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/hashtag-generator`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  summarizeText: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/summarize-text`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  commemorativeDate: async (params: any) => {
    const { data } = await instance().post(`/tools/commemorative-date`, {
      description: params.description,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  slogan: async (params: any) => {
    const { data } = await instance().post(`/tools/slogan`, {
      name: params.name,
      description: params.description,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  aidaStructure: async (params: any) => {
    const { data } = await instance().post(`/tools/aida-structure`, {
      name: params.name,
      description: params.description,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  contentImprover: async (params: any) => {
    const { data } = await instance().post(`/tools/content-improver`, {
      content: params.content,
      tone: params.tone
    });

    return {
      output: data.result
    };
  }
};

export default GenerativeRequest;
