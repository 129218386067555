import { ENTITY_TYPE, INLINE_STYLE, BLOCK_TYPE } from 'draftail';
import { Editor } from '../../../../index';

export const getSettings = () => {
  const ALIGN_TEXT_BLOCK: any = {
    LEFT: {
      type: 'text-align-left',
      icon: <Editor.Toolbar.Icon type="left" />
    },
    CENTER: {
      type: 'text-align-center',
      icon: <Editor.Toolbar.Icon type="center" />
    },
    RIGHT: {
      type: 'text-align-right',
      icon: <Editor.Toolbar.Icon type="right" />
    },
    JUSTIFY: {
      type: 'text-align-justify',
      icon: <Editor.Toolbar.Icon type="justify" />
    }
  };

  return {
    id: 'index',
    ariaDescribedBy: 'demo-editor-index',
    placeholder: 'Digite seu texto aqui...',
    enableHorizontalRule: true,
    spellCheck: false,
    stripPastedStyles: false,
    entityTypes: [ENTITY_TYPE.LINK, ENTITY_TYPE.IMAGE],
    blockTypes: [
      {
        type: BLOCK_TYPE.HEADER_ONE,
        icon: <Editor.Toolbar.Icon type="HEADER_ONE" />
      },
      {
        type: BLOCK_TYPE.HEADER_TWO,
        icon: <Editor.Toolbar.Icon type="HEADER_TWO" />
      },
      {
        type: BLOCK_TYPE.HEADER_THREE,
        icon: <Editor.Toolbar.Icon type="HEADER_THREE" />
      },
      {
        type: BLOCK_TYPE.HEADER_FOUR,
        icon: <Editor.Toolbar.Icon type="HEADER_FOUR" />
      },
      {
        type: BLOCK_TYPE.BLOCKQUOTE,
        icon: <Editor.Toolbar.Icon type="blockquote" />
      },
      {
        type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
        icon: <Editor.Toolbar.Icon type="unordered" />
      },
      {
        type: BLOCK_TYPE.ORDERED_LIST_ITEM,
        icon: <Editor.Toolbar.Icon type="ordered" />
      },
      ALIGN_TEXT_BLOCK.LEFT,
      ALIGN_TEXT_BLOCK.CENTER,
      ALIGN_TEXT_BLOCK.RIGHT,
      ALIGN_TEXT_BLOCK.JUSTIFY
    ],
    blockTypesMobile: [
      ALIGN_TEXT_BLOCK.LEFT,
      ALIGN_TEXT_BLOCK.CENTER,
      ALIGN_TEXT_BLOCK.RIGHT,
      ALIGN_TEXT_BLOCK.JUSTIFY,
      {
        type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
        icon: <Editor.Toolbar.Icon type="unordered" />
      },
      {
        type: BLOCK_TYPE.ORDERED_LIST_ITEM,
        icon: <Editor.Toolbar.Icon type="ordered" />
      }
    ],
    inlineStyles: [
      { type: INLINE_STYLE.BOLD, icon: <Editor.Toolbar.Icon type="bold" /> },
      {
        type: INLINE_STYLE.ITALIC,
        icon: <Editor.Toolbar.Icon type="italic" />
      },
      {
        type: INLINE_STYLE.STRIKETHROUGH,
        icon: <Editor.Toolbar.Icon type="strikethrough" />
      },
      {
        type: INLINE_STYLE.UNDERLINE,
        icon: <Editor.Toolbar.Icon type="underline" />
      }
    ],
    enableLineBreak: false,
    htmlExporterConfig: {
      // eslint-disable-next-line react/display-name
      blockToHTML: block => {
        if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
          return <blockquote />;
        }

        // // Discard atomic blocks, as they get converted based on their entity.
        if (block.type === BLOCK_TYPE.ATOMIC) {
          return {
            start: '',
            end: ''
          };
        }

        if (block.type === ALIGN_TEXT_BLOCK.LEFT.type) {
          return <div style={{ textAlign: 'left' }}>{block.text}</div>;
        }

        if (block.type === ALIGN_TEXT_BLOCK.CENTER.type) {
          return <div style={{ textAlign: 'center' }}>{block.text}</div>;
        }

        if (block.type === ALIGN_TEXT_BLOCK.RIGHT.type) {
          return <div style={{ textAlign: 'right' }}>{block.text}</div>;
        }

        if (block.type === ALIGN_TEXT_BLOCK.JUSTIFY.type) {
          return <div style={{ textAlign: 'justify' }}>{block.text}</div>;
        }

        return null;
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === ENTITY_TYPE.LINK) {
          return <a href={entity.data.url}>{originalText}</a>;
        }

        if (entity.type === ENTITY_TYPE.IMAGE) {
          return <img src={entity.data.src} alt={entity.data.alt} />;
        }

        if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
          return <hr />;
        }

        return originalText;
      }
    }
  };
};
